<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card
          :loading="loading"
          :disabled="loading"
          loader-height="2"
          class=""
        >
          <v-card-text class="pb-0">
            <div class="d-flex align-center">
              <v-btn
                rounded
                color="primary"
                elevation="0"
                small
                class="mr-2"
                v-if="$nova.hasRight(right, 'add')"
                @click="addForm()"
                ><v-icon class="mr-1">mdi-plus</v-icon>Add</v-btn
              >
              <lb-string
                label="Search"
                outlined
                hidedetails
                v-model="search"
              ></lb-string>
              <v-spacer></v-spacer>
              <v-chip label small class="border-left-error" outlined
                >Disabled</v-chip
              >
              <v-btn icon small class="ml-2" @click="getData()"
                ><v-icon>mdi-refresh</v-icon></v-btn
              >
            </div>
          </v-card-text>
          <v-spacer></v-spacer>
          
          <lb-dialog v-model="addRolesDialog" :heading="addeditdata._id ? 'Update' : 'Create'" width="800" :loading="addaccessloading">
              <template v-slot:body>
                <v-row class="">
                  <v-col cols="6" class="my-0 py-1">
                    <lb-string
                      label="Code"
                      v-model="addeditdata.code"
                      :error="errors['code']"
                    />
                  </v-col>
                  <v-col cols="6" class="my-0 py-1">
                    <lb-string
                      label="Name"
                      v-model="addeditdata.name"
                      :error="errors['name']"
                    />
                  </v-col>
                  <v-col cols="12" class="my-0 py-1">
                    <lb-dropdown
                      label="Product"
                      v-model="addeditdata.product"
                      :items="getProductListData"
                      itemtext="name"
                      itemvalue="_id"
                      :error="errors['product']"
                    />
                  </v-col>
                  <v-col cols="12" class="mb-0 py-1">
                    <v-btn
                      small
                      outlined
                      @click="addAccessForm()"
                      text
                      class="shadow-off"
                    >
                      Add Rights
                      <v-icon right dark> mdi-plus </v-icon>
                    </v-btn>
                  </v-col>

                  <v-col>
                    <div v-for="(v, k) in selectedRightList" :key="k">
                      <div class="">
                        <strong>
                          {{ (rightList[k] || {}).name || "Unknown" }}
                        </strong>
                      </div>
                        <v-checkbox
                          v-for="act in rightList[k].action || []" :key="act" :label="act" dense
                          class="d-inline-block mr-7" :value="act"
                          v-model="addeditdata.access[k]" multiple
                        ></v-checkbox>
                    </div>
                  </v-col>
                </v-row>
              </template>
              <!-- </v-card-text> -->
             <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click="addEditRoles()">
                  <span v-if="addeditdata._id">Update</span>
                  <span v-else>Create</span>
                </v-btn>
             </template>
            <!-- </v-card> -->
          </lb-dialog>
          <lb-dialog v-model="addAccessDialog" width="600" heading="Add Rights" :loading="addaccessloading">
                <template v-slot:body>
                  <v-row class="">
                    <v-col cols="12" class="my-0 py-1">
                      <lb-dropdown
                        label="Select"
                        v-model="addeditaccessdataright"
                        :error="errors['access']"
                        :items="rightListArray"
                        itemtext="name"
                        itemvalue="_id"
                        @change="resetActionList()"
                      />
                    </v-col>
                    <v-col cols="12" class="my-0 py-1">
                      <v-checkbox
                        v-for="(v, k) in accessAction" :key="k" :label="v" dense
                        class="d-inline-block mr-7" :value="v"
                        v-model="addeditaccessdataaction" multiple
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:actions>
                  <v-spacer></v-spacer>
                  <v-btn small color="primary" @click="saveAccessAction()"
                    >Save</v-btn
                  >
                </template>
            <!-- </v-card> -->
          </lb-dialog>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :search="search"
              hide-default-footer
              dense
              :items-per-page="-1"
              :items="roleData"
            >
              <template v-slot:[`item.product_detail`]="{ item }">
                <div v-for="(items, i) in item.product_detail" :key="i">
                  {{ items.name }}
                </div>
              </template>
              <template v-slot:[`item.access`]="{ item }">
                <div v-for="(items, i) in item.access" :key="i">
                  <span
                    >{{ (rightList[i] || {}).code || "Unknown" }} :
                    <span v-for="(item, k) of items" :key="k">
                      <v-chip label x-small class="ma-1">{{
                        item
                      }}</v-chip></span
                    ></span
                  >
                </div>
              </template>
              <template #[`item.code`]="{ item }">
                <td class="border-left-error" v-if="!item.status">
                  {{ item.code }}
                </td>
                <td class="border-left-transparent" v-else>{{ item.code }}</td>
              </template>
              <template #[`item.action`]="{ item }">
                <span v-for="i of item.action" :key="i">
                  <v-chip label small class="mr-1 mb-1">{{ i }}</v-chip>
                </span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu offset-y transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="view-on-hover-item-d mx-0 px-0"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense class="pa-0 border-left-default">
                    <v-list-item
                      v-if="$nova.hasRight(right, 'edit')"
                      @click="editItem(item)"
                    >
                      <v-list-item-title
                        ><v-icon left>mdi-pencil</v-icon>Edit</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      v-if="
                        item.status && $nova.hasRight(right, 'enable/disable')
                      "
                      @click="disableEnable(item._id, 'disable')"
                    >
                      <v-list-item-title
                        ><v-icon color="error" left>mdi-cancel</v-icon
                        >Disable</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      v-else-if="$nova.hasRight(right, 'enable/disable')"
                      @click="disableEnable(item._id, 'enable')"
                    >
                      <v-list-item-title
                        ><v-icon left color="success">mdi-check-bold </v-icon
                        >Enable</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
          <v-spacer></v-spacer>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDialog: false,
      right: "permission_role",
      search: "",
      loading: false,
      addeditdata: {},
      addRolesDialog: false,
      addAccessDialog: false,
      addaccessloading: false,
      addeditaccessdataaction: [],
      addeditaccessdataright: "",
      rightListArray: [],
      rightList: {},
      errors: {},
      getProductListData: [],
      selectedRightList : {},
      roleData: [],
      headers: [
        { text: "Code", value: "code", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Product", value: "product_detail.name", sortable: false },
        { text: "Access", value: "access", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      accessAction: [],
    };
  },
  created() {
    // this.getFormData();
    this.getData();
  },
  activated() {
    // this.getFormData();
    this.getData();
  },
  methods: {
    editItem(item) {
      this.addeditdata = { ...item };
      let newrightlist = {};
      for (const k in item.access) {
        if (Object.hasOwnProperty.call(item.access, k)) {
          const el = item.access[k];
          if (Object.prototype.hasOwnProperty.call(this.rightList, k))
            newrightlist[k] = el;
        }
      }
      this.addeditdata.access = { ...newrightlist };
      this.selectedRightList = {};
      this.selectedRightList = this.addeditdata.access;
      this.errors = {};
      this.addRolesDialog = true;
    },
    addForm() {
      this.addeditdata = {};
      this.errors = {};
      this.selectedRightList = {};
      this.addRolesDialog = true;
    },
    addAccessForm() {
      this.addAccessDialog = true;
      this.addeditaccessdataaction = "";
      this.addeditaccessdataright = [];
      this.accessAction = [];
    },
    saveAccessAction() {
      console.log(JSON.stringify(this.addeditaccessdataaction));
      this.addeditdata.access = this.addeditdata.access || {};
      this.addeditdata.access[this.addeditaccessdataright] = this.addeditaccessdataaction;
      this.addAccessDialog = false;
      this.selectedRightList = {};
      this.selectedRightList = this.addeditdata.access;
      console.log("changed 1", JSON.stringify(this.addeditdata.access));
    },
    resetActionList() {
      console.log(new Date(),this.addeditaccessdataaction);
      this.addeditaccessdataaction = [];
      console.log(new Date(),this.addeditaccessdataaction);
    },
    getData() {
      this.loading = true;
      this.axios
        .post("/v2/role/get")
        .then((ele) => {
          if (ele.data.status === "success") {
            this.roleData = ele.data.data;
          } else throw new Error("Error Fetching Data");
          return this.axios.post("/v2/role/getformdata");
        })
        .then((ele) => {
          if (ele.data.status === "success") {
            this.getProductListData = ele.data.data[0].products;
            this.rightListArray = ele.data.data[0].action;
            this.rightList = {};
            for (const i of this.rightListArray) {
              this.rightList[i._id] = i;
            }
          } else throw new Error("Error Fetching Data");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addEditRoles() {
      this.addaccessloading = true;
      let type = "add";
      let url = "/v2/role/add";
      if (this.addeditdata._id) {
        type = "edit";
        url = "/v2/role/edit/" + this.addeditdata._id;
      }
      for (const key in this.addeditdata.access) {
        if (Object.hasOwnProperty.call(this.addeditdata.access, key)) {
          if (this.addeditdata.access[key].length === 0) {
            delete this.addeditdata.access[key];
          }
        }
      }
      this.axios
        .post(url, { data: this.addeditdata })
        .then((ele) => {
          if (ele.data.status === "success" && ele.data.data.length > 0) {
            if (type === "add")
              this.$store.commit("sbSuccess", "Created Sucessfully");
            else this.$store.commit("sbSuccess", "Updated successfully");
            this.addRolesDialog = false;
            this.getData();
          } else {
            if (ele.data.data.length > 0) {
              if (ele.data.data[0].index0)
                this.errors = ele.data.data[0].index0;
            } else throw new Error(ele.data.message);
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.addaccessloading = false;
        });
    },
    // disableId(id) {
    //   this.loading = true;
    //   this.axios
    //     .post("/v2/role/disable/" + id)
    //     .then((ele) => {
    //       if (ele.data.status === "success") {
    //         this.getData();
    //       } else throw new Error("Error While Disable");
    //     })
    //     .catch((err) => {
    //       this.$store.commit("sbError", err.message || err || "Unknown error!");
    //       console.log(err);
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
    // enableId(id) {
    //   this.loading = true;
    //   this.axios
    //     .post("/v2/role/enable/" + id)
    //     .then((ele) => {
    //       if (ele.data.status === "success") {
    //         this.getData();
    //       } else throw new Error("Error While Enable");
    //     })
    //     .catch((err) => {
    //       this.$store.commit("sbError", err.message || err || "Unknown error!");
    //       console.log(err);
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },

    disableEnable(id, action) {
      this.loading = true;
      let url;
      if (action === "disable") {
        url = "/v2/role/disable/";
      } else if (action === "enable") {
        url = "/v2/role/enable/";
      } else {
        throw new Error("Invalid action");
      }
      this.axios
        .post(url + id)
        .then((ele) => {
          if (ele.data.status === "success") {
            if (action === "disable")
              this.$store.commit("sbSuccess", "Disabled Sucessfully");
            else this.$store.commit("sbSuccess", "Enabled successfully");
            this.getData();
          } else {
            throw new Error("Error While " + action);
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
  },
  watch: {
    "addeditaccessdataright": function (event) {
      if (event) {
        this.accessAction = (this.rightList[event] || {}).action || [];
      }
    },
    "addeditaccessdataaction": function(d) {
      console.log("watch", d);
    }
  },
};
</script>
